<template>
  <div class="confirm_team">
    <NavBar />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <div class="container">
      <div class="main-area">
        <div class="confirm_team__form">
          <div class="confirm_team__form_box">
            <h3 class="confirm_team__form_title">新規チームの作成</h3>
            <p class="confirm_team__form_note">新規に作成したいチーム内容を入力してください。チームメンバーを招待する場合、一度チーム登録完了後にチーム詳細ページから招待することができます。</p>
            <form class="horizontal">
              <div class="form-ctrl">
                <label  class="text">チーム名<span>（必須）</span></label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <p>{{ state.data.name || "" }}</p>
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.name
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage.name"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
                <SimplePreloader v-else />
              </div>
              <div class="form-ctrl">
                <label  class="text"
                  >（チーム名）カナ<span>（必須）</span></label
                >
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <p>{{ state.data.name_kana }}</p>
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.name_kana
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage
                            .name_kana"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
                <SimplePreloader v-else />
              </div>
              <div class="form-ctrl">
                <label  class="text height-unset"
                  >チーム画像 200 x 200px
                  <span>※検索結果に表示されます</span></label
                >
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <div class="image_team">
                        <img v-if="state.data.image" :src="state.data.image" alt="" />
                      </div>
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.image
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage
                            .image"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
                <SimplePreloader v-else />
              </div>
              <div class="form-ctrl">
                <label  class="text">
                  PRポイント
                  <span>（必須）</span>
                </label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <pre>{{state.data.description}}</pre>
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.description
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage.description"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
                <SimplePreloader v-else />
              </div>
              <div class="btn_group">
                <button
                  class="btn-md btn-light-gray shadow"
                  type="button"
                  @click="goBack"
                >
                  キャンセル
                </button>
                <button
                  :disabled="state.loading"
                  :class="{ submitting: state.loading }"
                  type="button"
                  class="btn-md btn-blue shadow"
                  @click="submit"
                >
                  <i
                    v-if="state.loading"
                    class="fas fa-circle-notch fa-spin"
                  ></i
                  >登録完了
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/team/confirm-team/ConfirmTeam.ts">
</script>

<style lang="scss" src="@/presentation/views/user/team/confirm-team/ConfirmTeam.scss" scoped>
</style>
