import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import NavBar from "@/presentation/components/navigation/Navigation.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import LanguageUtil from "@/commons/LanguageUtil";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import routePath from "@/commons/RoutePath";
import { useStore } from "vuex";
import SimplePreloader from "@/presentation/components/preloader/simple-preloader/SimplePreloader.vue";
import SkillTagPreloader from "@/presentation/components/preloader/skill-tag-preloader/SkillTagPreloader.vue";
import { getCurrentAccountId } from "@/commons/UserStatusUtis";
export default defineComponent({
    name: "ConfirmTeam",
    components: {
        Breadcrumb: Breadcrumb,
        NavBar: NavBar,
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        SimplePreloader: SimplePreloader,
        SkillTagPreloader: SkillTagPreloader
    },
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: "/user/dashboard",
                    name: "ダッシュボード"
                },
                {
                    route: "",
                    name: "プロフィール"
                },
                {
                    route: "",
                    name: "新規チーム作成"
                }
            ],
            data: {},
            loading: false,
            errorValidateMessage: [],
            serverError: "",
            preload: true
        });
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        onMounted(function () {
            state.listBreadcrumb[1].route = routePath.USER_PROFILE + "/" + getCurrentAccountId() + "/info";
        });
        function initData() {
            state.data = store.state.createTeamData;
            if (!state.data.name ||
                !state.data.name_kana ||
                !state.data.description) {
                return router.back();
            }
            state.preload = false;
        }
        function submit() {
            state.loading = true;
            return teamInteractor.createTeam(state.data).then(function (result) {
                state.loading = false;
                store.commit("setCreateTeamCompleted", true);
                store.commit("setCreateTeamData", {});
                router.push(routePath.USER_PROFILE + "/" + result.data.owner_id + "/team");
            }).catch(function (error) {
                state.loading = false;
                if (error.validation_errors) {
                    state.errorValidateMessage = Object.assign({}, error.validation_errors);
                }
                if (error.message) {
                    state.serverError = error.message;
                }
            });
        }
        function goBack() {
            router.back();
        }
        onMounted(function () {
            initData();
        });
        return {
            state: state,
            goBack: goBack,
            submit: submit
        };
    }
});
